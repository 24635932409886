export const FETCH_PRACTICE_SETTINGS_REQUEST =
  "FETCH_PRACTICE_SETTINGS_REQUEST";
export const FETCH_PRACTICE_SETTINGS_SUCCESS =
  "FETCH_PRACTICE_SETTINGS_SUCCESS";
export const FETCH_PRACTICE_SETTINGS_FAILURE =
  "FETCH_PRACTICE_SETTINGS_FAILURE";

export const FETCH_PRACTICE_IN_PRACTICE_SETTINGS_REQUEST =
  "FETCH_PRACTICE_IN_PRACTICE_SETTINGS_SUCCESS";
export const FETCH_PRACTICE_IN_PRACTICE_SETTINGS_SUCCESS =
  "FETCH_PRACTICE_IN_PRACTICE_SETTINGS_SUCCESS";
export const FETCH_PRACTICE_IN_PRACTICE_SETTINGS_FAILURE =
  "FETCH_PRACTICE_IN_PRACTICE_SETTINGS_FAILURE";

export const SUBMIT_PRACTICE_SETTINGS_REQUEST =
  "SUBMIT_PRACTICE_SETTINGS_REQUEST";
export const SUBMIT_PRACTICE_SETTINGS_SUCCESS =
  "SUBMIT_PRACTICE_SETTINGS_SUCCESS";
export const SUBMIT_PRACTICE_SETTINGS_FAILURE =
  "SUBMIT_PRACTICE_SETTINGS_FAILURE";
export const SUBMIT_PRACTICE_SETTINGS_ONLY_SUCCESS =
  "SUBMIT_PRACTICE_SETTINGS_ONLY_SUCCESS";

export const SUBMIT_HOLPITAL_IN_PRACTICE_SETTINGS_REQUEST =
  "SUBMIT_HOLPITAL_IN_PRACTICE_SETTINGS_REQUEST";
export const SUBMIT_HOLPITAL_IN_PRACTICE_SETTINGS_SUCCESS =
  "SUBMIT_HOLPITAL_IN_PRACTICE_SETTINGS_SUCCESS";
export const SUBMIT_HOLPITAL_IN_PRACTICE_SETTINGS_FAILURE =
  "SUBMIT_HOLPITAL_IN_PRACTICE_SETTINGS_FAILURE";

export const SAVE_PRACTICE_PRACTICE_SETTINGS_REQUEST =
  "SAVE_PRACTICE_PRACTICE_SETTINGS_REQUEST";
export const SAVE_PRACTICE_PRACTICE_SETTINGS_SUCCESS =
  "SAVE_PRACTICE_PRACTICE_SETTINGS_SUCCESS";
export const SAVE_PRACTICE_PRACTICE_SETTINGS_FAILURE =
  "SAVE_PRACTICE_PRACTICE_SETTINGS_FAILURE";
