import { getRequest, postRequest, patchRequest } from "../../base";
import { normalize } from "utils/data-helpers";
import { getItemPrice } from "utils/product-helpers";
import {
  orderDataFactory,
  GET_ONE_ORDER_MODES,
  fromServerToClient,
  handleModes,
} from "../../order-helpers";
import { getGroupsItemsCombined } from "../../treatment-plan-helpers";
import { path } from "../../path-helpers";

import { PRACTICE, DISPENSER } from "constants/portalTypes";

export const getList = (base) => async ({
  q = "",
  skip = 0,
  limit = 10,
  filters,
}) => {
  const { orders, count } = await getRequest(path(base, "orders"), {
    q,
    skip,
    limit,
    ...filters,
  });

  return { count, orders };
};

export const getOne = (base) => (
  order_id,
  defaultModes = [],
  existingData = {}
) => {
  const modes = {
    [PRACTICE]: [GET_ONE_ORDER_MODES.WITH_ORDER_CONTENTS],
    [DISPENSER]: [],
  };
  return getRequest(path(base, `orders/${order_id}`))
    .then((order) => fromServerToClient(order_id, order))
    .then((order) =>
      handleModes(order, [...defaultModes, ...modes[base]], existingData)
    );
};

export const getOrderPrice = (base) => (order) => {
  const items =
    order.items.length !== 0
      ? order.items
      : getGroupsItemsCombined(order.groups);

  const prepareData = items.map((item) => ({
    product_id: item.product_id,
    type: item.type,
    amount: item.amount,
    refills: item.amount,
    price: getItemPrice(item).toFixed(2),
  }));

  return postRequest(path(base, "orders/order_total"), {
    items: normalize(prepareData),
  });
};

export const submit = (base) => (params, paymentAndShipping) => {
  const order = orderDataFactory(params, paymentAndShipping);
  const { update: updateOrder, create: createOrder } = OrdersApi(base);

  if (params.order_id) {
    return updateOrder(params.order_id, order);
  } else {
    return createOrder(order);
  }
};

export const create = (base) => (order) => {
  return postRequest(path(base, "orders"), order).then((response) => {
    if (response.errors && response.errors.length) {
      throw response;
    } else {
      return response;
    }
  });
};

export const update = (base) => (order_id, params) => {
  return patchRequest(path(base, `orders/${order_id}`), params).then(
    (response) => {
      if (response && response.errors && response.errors.length) {
        throw response;
      } else {
        return response;
      }
    }
  );
};

export const cancel = (base) => (order_id, cancelationReason) => {
  return patchRequest(path(base, `orders/${order_id}/cancel`), {
    order_status_comments: cancelationReason,
  });
};

export const fetchNotes = (base) => (order_id) => {
  return getRequest(path(base, `orders/${order_id}/notes`)).then(
    (json) => json.notes
  );
};

const OrdersApi = (base) => {
  return {
    getList: getList(base),
    getOne: getOne(base),
    getOrderPrice: getOrderPrice(base),
    submit: submit(base),
    create: create(base),
    update: update(base),
    cancel: cancel(base),
    fetchNotes: fetchNotes(base),
  };
};

export default OrdersApi;
