import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";

import {
  addGroup,
  removeGroup,
  addProductToBox,
  removeProductFromBox,
  updateProductInBox,
} from "utils/template-helpers";

export const defaultTemplate = {
  groups: [],
  cadence: 3,
};

const templateReducer = (state = defaultTemplate, action) => {
  switch (action.type) {
    case types.NEW_ORDER_FETCH_ORDER_SUCCESS: {
      const { order } = action;
      if (order.subscription) {
        return objectAssign({}, order.subscription);
      } else {
        return state;
      }
    }
    case types.NEW_ORDER_USE_TEMPLATE_SUCCESS: {
      const { template } = action;
      return objectAssign({}, state, template);
    }
    case types.NEW_ORDER_USE_TEMPLATE_REQUEST:
    case types.NEW_ORDER_FLOW_RESET:
      return defaultTemplate;
    case types.NEW_ORDER_UPDATE_SUBSCRIPTION:
      return objectAssign({}, state, action.updates);
    case types.NEW_ORDER_ADD_BOX: {
      return addGroup(state, action);
    }
    case types.NEW_ORDER_REMOVE_ORDER: {
      return removeGroup(state, action);
    }
    case types.NEW_ORDER_ADD_PRODUCT_TO_BOX_SUCCESS: {
      return addProductToBox(state, action);
    }
    case types.NEW_ORDER_REMOVE_PRODUCT_FROM_BOX: {
      return removeProductFromBox(state, action);
    }
    case types.NEW_ORDER_UPDATE_PRODUCT_IN_BOX: {
      return updateProductInBox(state, action);
    }
    case LOAD_FROM_CACHE:
      return action.state.template;
    default:
      return state;
  }
};

export default templateReducer;
