import { capitalize } from "./string-helpers";

export const VISA = "visa";
export const MASTERCARD = "mastercard";
export const AMERICAN_EXPRESS = "americanexpress";
export const AMEX = "amex";
export const DISCOVER = "discover";

export const AMEX_ALIASES = [AMERICAN_EXPRESS, AMEX];
export const ALL_TYPES = [VISA, MASTERCARD, AMERICAN_EXPRESS, DISCOVER];

export const typeByNumber = (number) => {
  switch (number && number[0]) {
    case "3":
      return AMERICAN_EXPRESS;
    case "4":
      return VISA;
    case "5":
      return MASTERCARD;
    case "6":
      return DISCOVER;
    default:
      return null;
  }
};

export const cvvMaskByType = (type) =>
  AMEX_ALIASES.includes(type) ? "0000" : "000";

export const numberMaskByType = (type) => {
  return AMEX_ALIASES.includes(type)
    ? "0000 000000 00000"
    : "0000 0000 0000 0000";
};

export const idtechMaskByType = (type, rawValue) => {
  const matchArray = rawValue.match(/(?<=;).*(?=\?)/) || [];
  const cardValue = matchArray[0];
  if (!cardValue) return null;
  const card_type = type ? type : typeByNumber(cardValue);
  const splittedString = cardValue.split("=");
  const number = splittedString[0];
  const last_4 = number.slice(-4);
  const year = splittedString[1].slice(0, 2);
  const month = splittedString[1].slice(2, 4);

  const expiredString = `(Expires ${month}/20${year})`;
  const mask = AMEX_ALIASES.includes(type)
    ? `**** ****** *${last_4}`
    : `**** **** **** ${last_4}`;
  return {
    mask: `${capitalize(card_type)} ${mask} ${expiredString}`,
    card_type,
    last_4,
    number,
  };
};

export const getLast4 = (number) => {
  const splitted = number.split(" ");
  return splitted[splitted.length - 1];
};

export const parseCard = (card = {}) => {
  const { isIdtechCard } = card;
  if (isIdtechCard) return card;

  const [expiry_month, expiry_year] = card.expiration_date
    ? card.expiration_date.split("/")
    : [null, null];

  return {
    name: card.name,
    number: card.number || "",
    expiry_month: card.expiry_month || expiry_month,
    expiry_year: card.expiry_year || (expiry_year && `20${expiry_year}`) || "",
    cvv: card.cvv || "",
    last_4: card.last_4 || "",
  };
};

export const showCardDetails = (card, patient_card = {}) => {
  const { last_4: patient_card_last_4 } = patient_card;
  const { cvv } = card;

  if (cvv) {
    return true;
  } else if (!cvv && !patient_card_last_4) {
    return true;
  } else if (!cvv && patient_card_last_4) {
    return false;
  }
};
