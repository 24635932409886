import { isRequired } from "utils/validators";
import {
  creditCardNumber,
  zipcodeOldFormatValidation,
  creditCardCVV,
  isIdtechMask,
} from "utils/validations";

export const card = {
  type: [{ validator: isRequired, message: "REQUIRED SELECTION" }],
  name: [{ validator: isRequired }],
  number: [{ validator: isRequired }, { validator: creditCardNumber }],
  expiry_month: [{ validator: isRequired, message: "REQUIRED SELECTION" }],
  expiry_year: [{ validator: isRequired, message: "REQUIRED SELECTION" }],
  cvv: [{ validator: isRequired }, { validator: creditCardCVV }],
};

export const idtechCard = {
  name: [{ validator: isRequired }],
  masked_card: [{ validator: isRequired }, { validator: isIdtechMask }],
  cvv: [{ validator: isRequired }, { validator: creditCardCVV }],
};

export const address = {
  street_1: [{ validator: isRequired }],
  city: [{ validator: isRequired }],
  state: [{ validator: isRequired }],
  zip: [{ validator: isRequired }, { validator: zipcodeOldFormatValidation }],
};

export const health_insurance = {
  payor_id: [{ validator: isRequired, message: "REQUIRED SELECTION" }],
  payor_name: [],
  account_number: [{ validator: isRequired }],
  group_number: [{ validator: isRequired }],
  rx_bin: [{ validator: isRequired }],
  insurer_phone: [],
  pc_number: [{ validator: isRequired }],
};
