export const card = {
  name: "",
  number: "",
  expiry_month: "",
  expiry_year: "",
  cvv: "",
  masked_card: "",
};

export const address = {
  street_1: "",
  street_2: "",
  city: "",
  state: "",
  zip: "",
};

export const health_insurance = {
  payor_name: "",
  payor_id: "",
  account_number: "",
  group_number: "",
  rx_bin: "",
  insurer_phone: "",
  pc_number: "",
};
