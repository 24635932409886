import * as types from "../OrderNew/constants/actionTypes";
import { push, replace } from "connected-react-router";
import { createPromiseWithTimeout } from "utils/action-helpers";
import {
  getPortal,
  getRole,
  isPractice,
  isHubDispenser,
} from "utils/auth-helpers";
import { extractUpdates } from "utils/data-helpers";
import { reset as resetPaymentAndShipping } from "shared/containers/PaymentAndShipping/actions";
import { SUBSCRIPTION } from "constants/orders/order_types";
import { PRACTICE_ROLES } from "constants/accountRoles";
import * as PORTAL_TYPES from "constants/portalTypes";
import { isOrderOTC } from "utils/order-helpers";

import { showPopup } from "app-shared/containers/Popup/actions";
import { parseCard } from "utils/card-helpers";
import { APIModule } from "api";
import { addressToServerFormat } from "api/address-helpers";
import { insuranceToServerFormat } from "api/payment-helpers";
import {
  getOrder,
  getPaymentAndShipping,
  getPatient,
  getDoctor,
  getNotes,
  getTemplate,
} from "modules/orderFlow/selectors";

export { updateOrder, fetchDispensers } from "../OrderDetailsPage/actions";

const getOrderChanges = (state) => {
  const oldOrder = state.uniform.orders.existingOrderDetails.order;
  const order = getOrder(state);

  const orderChanges = extractUpdates(order, oldOrder);

  return {
    order_id: order.order_id,
    ...orderChanges,
    items: orderChanges.items ? order.items : null,
    dropcharts: order.dropcharts,
  };
};

const getSubmitData = (state, isEditing) => {
  const order = getOrder(state);
  const payment_and_shipping = getPaymentAndShipping(state);
  const template = getTemplate(state);

  const orderUpdates = isEditing ? getOrderChanges(state) : order;

  const paymentUpdates = isEditing
    ? {
        card: parseCard(payment_and_shipping.card),
        shipping_address: addressToServerFormat(
          payment_and_shipping.shipping_address
        ),
        billing_address: addressToServerFormat(
          payment_and_shipping.billing_address
        ),
        health_insurance: insuranceToServerFormat(
          payment_and_shipping.health_insurance
        ),
        ui: payment_and_shipping.ui || {},
      }
    : payment_and_shipping;

  return {
    order: orderUpdates,
    payment_and_shipping: paymentUpdates,
    template,
  };
};

const isEditingOrder = (state) => {
  const { order_id } = getOrder(state);
  const isOrderIdValid = typeof order_id === "string" && order_id;

  return isOrderIdValid;
};

export const submit = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.SUBMIT_ORDER_REQUEST });

    let success;
    let order_id;
    const state = getState();
    const portal = getPortal();

    const patient = getPatient(state);
    const doctor = getDoctor(state);
    const notes = getNotes(state);

    const isEditing = isEditingOrder(getState());

    const { order, payment_and_shipping, template } = getSubmitData(
      state,
      isEditing
    );

    const isOTC = isOrderOTC(
      isEditing ? (order.items ? order : getOrder(state)) : order,
      template
    );

    const APIEndpoint = APIModule(portal);
    const apiOperation =
      order.type === SUBSCRIPTION ? "subscriptions" : "orders";

    let payload;
    if (order.order_id) {
      // takes care of order update
      payload = order;
    } else {
      // takse care of order create
      payload = notes.length ? { ...order, notes } : order;
    }

    APIEndpoint[apiOperation]
      .submit(payload, payment_and_shipping, template)
      .then(async (response) => {
        success = true;
        order_id = response ? response._id : order.order_id;

        if (!isPractice()) {
          dispatch({
            type: types.GET_CREATED_ORDER_SUCCESS,
            order: response,
          });
        } else {
          /**
           * if @const order_id is undefined, we are
           * interrupt our Promise pipeline to trigger .catch
           */
          if (typeof order_id === "undefined") {
            throw new Error("Cannot get order without order_id!");
          }
          const order = await APIModule(PORTAL_TYPES.PRACTICE).orders.getOne(
            order_id
          );
          dispatch(showSuccessfulSubmit({ order, doctor, patient }));
          return order;
        }
      })
      .then(() => {
        if (isPractice()) {
          const isDoctor = getRole() === PRACTICE_ROLES.DOCTOR;
          dispatch({
            type: types.GET_CREATED_ORDER_SUCCESS,
            // TODO: move all hardcoded text to i18n module
            header: "You're all set!",
            subtitle:
              isDoctor || isOTC
                ? "An email confirmation has been sent to the patient."
                : "You've successfully added this Rx to the selected doctor's queue for approval.",
          });
        }
      })
      .then(() => {
        dispatch({ type: types.SUBMIT_ORDER_NOTIFICATION_SENT });
        return createPromiseWithTimeout(2000);
      })
      .catch((message) => {
        if (message.order_number) {
          dispatch({
            type: types.GET_CREATED_ORDER_FAILURE,
            header: `Order #${
              message.order_number
            } has been placed, but ${message.errors.join(" ")}`,
            description: message.errors[0] || "There was some issue",
            order: { order_id: message._id },
          });
        } else {
          dispatch({
            type: types.GET_CREATED_ORDER_FAILURE,
            header: "Order Unsuccessful",
            description: "There was an error creating your order",
          });
        }

        return message;
      })
      .finally(() => {
        isPractice() &&
          dispatch(resetPaymentAndShipping()) &&
          dispatch({ type: types.NEW_ORDER_FLOW_RESET }) &&
          dispatch(replace(`/${getPortal()}/orders/new/completed`));
        !isPractice() && dispatch(showPopupsOnDispenser(success, isOTC));
      });
  };
};

const showSuccessfulSubmit = ({ order = {}, doctor, patient }) => ({
  type: types.GET_CREATED_ORDER_SUCCESS,
  payload: {
    _id: order.order_id,
    order_number: order.order_number,
    patient_name: patient.patient_name,
    patient_email: patient.patient_email,
    doctor_name: `${doctor.first_name} ${doctor.last_name}`,
  },
});

export const fetchPrescriberDetails = (doctor_id) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_PRESCRIBER_DETAILS_REQUEST });

    const portal = getPortal();
    APIModule(portal)
      .doctors.getOne(doctor_id)
      .then((doctor) =>
        dispatch({ type: types.FETCH_PRESCRIBER_DETAILS_SUCCESS, doctor })
      )
      .catch((error) =>
        dispatch({ type: types.FETCH_PRESCRIBER_DETAILS_FAILURE, error })
      );
  };
};

const showPopupsOnDispenser = (success, isOTC) => (dispatch, getState) => {
  if (success) {
    const isEditing = isEditingOrder(getState());
    const text = generateTextForDispenser(isEditing, isOTC);
    dispatch(showPopup(text));
  }
};

const generateTextForDispenser = (isEditing, isOTC) => {
  if (isEditing) {
    return "Order has been successfully updated.";
  }

  return isHubDispenser() || isOTC
    ? "Order has been created successfully"
    : "Congratulations, you've successfully added this Rx to the selected doctor's queue for approval.";
};

export const dismissPopupsOnDispenser = () => {
  return (dispatch, getState) => {
    const portal = getPortal();
    const { order_id } = getOrder(getState());
    const isEditing = isEditingOrder(getState());

    dispatch({ type: types.NEW_ORDER_FLOW_RESET });

    !isEditing &&
      dispatch(resetPaymentAndShipping()) &&
      dispatch(push(`/${portal}`));

    isEditing && dispatch(push(`/${portal}/orders/${order_id}`));
  };
};
