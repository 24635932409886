import {
  FETCH_DISPENSERS_IN_ADMIN_REQUEST,
  FETCH_DISPENSERS_IN_ADMIN_SUCCESS,
  FETCH_DISPENSERS_IN_ADMIN_FAILURE,
} from "app-master/containers/DispensersPage/constants/actionTypes";
import {
  FETCH_PRACTICES_IN_ADMIN_REQUEST,
  FETCH_PRACTICES_IN_ADMIN_SUCCESS,
  FETCH_PRACTICES_IN_ADMIN_FAILURE,
} from "app-master/containers/PracticesPage/constants/actionTypes";
import { generateSearchHints } from "shared/components/SearchWithHints";

export const defaultState = {
  loading: false,
  dispensers: [],
  maxDispensers: 0,
  practices: [],
  maxPractices: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_DISPENSERS_IN_ADMIN_REQUEST:
    case FETCH_PRACTICES_IN_ADMIN_REQUEST:
      return { ...state, loading: true };
    case FETCH_DISPENSERS_IN_ADMIN_FAILURE:
    case FETCH_PRACTICES_IN_ADMIN_FAILURE:
      return { ...state, loading: false };
    case FETCH_DISPENSERS_IN_ADMIN_SUCCESS:
      return {
        ...state,
        dispensers: generateSearchHints(action.dispensers),
        maxDispensers: action.count,
        loading: false,
      };
    case FETCH_PRACTICES_IN_ADMIN_SUCCESS:
      return {
        ...state,
        practices: generateSearchHints(action.practices),
        maxPractices: action.count,
        loading: false,
      };
    default:
      return state;
  }
};
