import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

class Confirmation extends PureComponent {
  constructor(props) {
    super(props);

    this.renderFooter = this.renderFooter.bind(this);
  }

  renderFooter() {
    const {
      footerContent,
      submitText,
      yesAction,
      cancelText,
      noAction,
      disabled,
      dismissAction,
      dismissText,
    } = this.props;

    if (footerContent) return footerContent;

    return !yesAction && dismissAction ? (
      <Button
        disabled={disabled}
        bsStyle="primary"
        bsSize="large"
        onClick={dismissAction}
      >
        {dismissText || "Dismiss"}
      </Button>
    ) : (
      <>
        <Button
          disabled={disabled}
          bsStyle="primary"
          bsSize="large"
          onClick={yesAction}
        >
          {submitText || "Yes"}
        </Button>
        <Button
          disabled={disabled}
          bsSize="large"
          onClick={noAction || dismissAction}
        >
          {cancelText || "No"}
        </Button>
      </>
    );
  }

  render() {
    const {
      show,
      headerContent,
      children,
      dismissAction,
      className,
      apiErrorStatus,
    } = this.props;

    const isLocalAndShow = !apiErrorStatus && className !== "modalGlobal";
    const isGlobalAndShow = !isLocalAndShow && className === "modalGlobal";

    return (
      <div className="modal-wrapper">
        <Modal
          show={(show && isGlobalAndShow) || (show && isLocalAndShow)}
          onHide={dismissAction}
          className={`confirmation-modal ${className}`}
        >
          <Modal.Header closeButton>{headerContent}</Modal.Header>
          <Modal.Body>{children}</Modal.Body>
          <Modal.Footer>{this.renderFooter()}</Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  location: PropTypes.object,
  show: PropTypes.bool.isRequired,
  headerContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footerContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  apiErrorStatus: PropTypes.number,
  submitText: PropTypes.string,
  yesAction: PropTypes.func,
  cancelText: PropTypes.string,
  noAction: PropTypes.func,
  dismissAction: PropTypes.func,
  dismissPopupsOnDispenser: PropTypes.func,
  dismissText: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Confirmation.defaultProps = {
  show: false,
  disabled: false,
};

export default Confirmation;
