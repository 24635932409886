import * as types from "../constants/actionTypes";
import { LOGIN_SUCCESS } from "shared/containers/LoginPage/constants/actionTypes";

export const defaultState = {
  show: false,
  children: null,
  apiErrorStatus: null,
  yesAction: null,
  isYesActionExists: false,
  noAction: null,
  dismissAction: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_POPUP: {
      return {
        ...state,
        show: true,
        children: action.children || action.text,
        apiErrorStatus: action.apiErrorStatus,
        isYesActionExists: action.isYesActionExists,
      };
    }
    case types.DISMISS_POPUP:
    case LOGIN_SUCCESS:
      return defaultState;
    default:
      return state;
  }
};
