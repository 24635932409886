import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";
import * as defaults from "constants/defaults/paymentAndShipping";
import * as validations from "../constants/validations";
import { isValidURL } from "../../OrderNew/constants/validations";
import { getPortal } from "utils/auth-helpers";
import {
  updateStateWithErasingErrors,
  newValidate as validate,
} from "utils/validators";
import { parseCard } from "utils/card-helpers";
import { LOCATION_CHANGE } from "connected-react-router";
import { NEW_ORDER_FETCH_ORDER_SUCCESS } from "shared/containers/OrderNew/constants/actionTypes";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";

const cardReducer = (state = defaults.card, action) => {
  switch (action.type) {
    case types.PAYMENT_AND_SHIPPING_CARD_UPDATE:
      return updateStateWithErasingErrors(state, action.updates);
    case NEW_ORDER_FETCH_ORDER_SUCCESS: {
      const { payment_details } = action.order;
      const card = payment_details && parseCard(payment_details.card);
      return objectAssign({}, state, card, { errors: {} });
    }
    case types.PAYMENT_AND_SHIPPING_BULK_UPDATE: {
      return objectAssign({}, state, action.card, { errors: {} });
    }
    case types.PAYMENT_AND_SHIPPING_VALIDATE: {
      if (state.last_4 && !state.isIdtechCard) {
        return state;
      } else {
        return objectAssign({}, state, {
          errors: validate(state, validations.idtechCard),
        });
      }
    }
    case types.PAYMENT_AND_SHIPPING_RESET_ALL:
    case types.PAYMENT_AND_SHIPPING_RESET:
      return defaults.card;
    case LOCATION_CHANGE: {
      const { pathname } = action.payload.location;
      if (
        !pathname.includes("/orders/") &&
        !isValidURL(pathname?.replace(`/${getPortal()}/`, "") || "")
      ) {
        return defaults.card;
      }
      return state;
    }
    case LOAD_FROM_CACHE:
      return action.state.paymentAndShipping.card;
    default:
      return state;
  }
};

export default cardReducer;
