import isBoolean from "lodash/isBoolean";

export const capitalize = (str) => {
  return (
    str &&
    str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
  );
};

export const snakeCaseToHuman = (str) => {
  return capitalize(str.replace("_", " "));
};

export const pluralize = (count, single, plural = null) => {
  if (count > 1) {
    return plural || `${single}s`;
  } else {
    return single;
  }
};

export const truncate = (string, maxLength = 16) => {
  if (string && string.length <= maxLength) {
    return string;
  } else {
    return string.slice(0, maxLength) + "...";
  }
};

export const booleanToString = (
  value,
  { trueValue = "Yes", falseValue = "No", undefindeValue = "-" } = {}
) => {
  if (isBoolean(value)) {
    return value ? trueValue : falseValue;
  } else {
    return undefindeValue;
  }
};

export const stringToBoolean = (value) => value === "true";

export const isUpperCase = (str) => {
  for (let i = 0; i < str.length; i++) {
    if (str[i] === str[i].toUpperCase() && str[i] !== str[i].toLowerCase()) {
      return true;
    }
  }
  return false;
};

export const getLastNChars = (str, quantity) => {
  if (!str) return null;

  return str.slice(-quantity);
};
