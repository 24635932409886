import * as types from "../constants/actionTypes";
import { LOCATION_CHANGE } from "connected-react-router";

export const defaultState = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  provider_id: "",
  type: "",
  is_admin: true,
  role: "",
  permissions: {
    orders: {
      create: true,
      update: true,
      delete: true,
      read: true,
    },
    prescriptions: {
      create: true,
      update: true,
      delete: true,
      read: true,
    },
  },
};

const userFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ONE_USER_REQUEST:
    case types.SUBMIT_USER_REQUEST:
      return defaultState;
    case types.FETCH_ONE_USER_SUCCESS:
      return action.user;
    case LOCATION_CHANGE: {
      const { pathname } = action.payload.location;
      if (pathname.includes("/users/new")) {
        return defaultState;
      }
      return state;
    }
    default:
      return state;
  }
};

export default userFormReducer;
