import { getRequest, postRequest, patchRequest } from "../base";
import { normalize } from "utils/data-helpers";
import { getUserId } from "utils/auth-helpers";

import commonApiServices from "../shared";
import { dispenserEndpoint as portalEndpoint } from "../path-helpers";
import { DISPENSER } from "constants/portalTypes";

export const completeOrder = (order_id, completionReason) => {
  const params = completionReason && {
    order_status_comments: completionReason,
  };

  return patchRequest(portalEndpoint(`orders/${order_id}/complete`), params);
};

export const createNote = ({ order_id, ...noteDetails }) => {
  return postRequest(portalEndpoint(`orders/${order_id}/notes`), noteDetails);
};

export const sendOutOfNetwork = (order_id, sendOutReason) => {
  const params = sendOutReason && {
    order_status_comments: sendOutReason,
  };

  return patchRequest(
    portalEndpoint(`orders/${order_id}/out_of_network`),
    params
  );
};

export const updateNote = ({ order_id, note_id, text, show_to_pharmacy }) => {
  const user_id = getUserId();

  return patchRequest(portalEndpoint(`orders/${order_id}/notes/${note_id}`), {
    author: user_id,
    text,
    show_to_pharmacy,
  });
};

export const removeNote = ({ order_id, note_id, remove }) => {
  const user_id = getUserId();

  return patchRequest(portalEndpoint(`orders/${order_id}/notes/${note_id}`), {
    author: user_id,
    remove,
  });
};

// Postage Labels Things

const ship_to_from_schema = {
  name: { type: "String" },
  street_1: { type: "String" },
  street_2: { type: "String" },
  city: { type: "String" },
  state: { type: "String" },
  zip: { type: "String" },
  phone: { type: "String" },
};

const weight_schema = {
  pounds: { type: "Interger" },
  ounces: { type: "Interger" },
};

const dimensions_schema = {
  length: { type: "Float" },
  height: { type: "Float" },
  width: { type: "Float" },
};

const schema = {
  parcel_type: { type: "String" },
  service_type: { type: "String" },
  rate_id: { type: "String" },
  weight: { type: "Object", schema: weight_schema },
  ship_to: { type: "Object", schema: ship_to_from_schema },
  ship_from: { type: "Object", schema: ship_to_from_schema },
  shipping_amount: { type: "Float" },
};

export const normalizeLabel = (params) => {
  let result = normalize(params, schema);

  if (result.parcel_type === "other") {
    const dimensions = normalize(params.dimensions, dimensions_schema);
    result.dimensions = dimensions;
  }

  return result;
};

export const getRates = (order_id, params) => {
  return postRequest(
    portalEndpoint(`orders/${order_id}/rates`),
    normalizeLabel(params)
  );
};

export const createLabel = (order_id, params) => {
  return postRequest(
    portalEndpoint(`orders/${order_id}/label`),
    normalizeLabel(params)
  );
};

export const getLabel = (order_id) => {
  return getRequest(portalEndpoint(`orders/${order_id}/label`)).then(
    (response) => ({
      label_url: response.label_url,
      file_url: response.label_pdf || response.label_zpl,
    })
  );
};

export const transfer = (order_id) => {
  return patchRequest(portalEndpoint(`orders/${order_id}/transfer`));
};

export const confirmPatientApproval = (order_id) => {
  return patchRequest(portalEndpoint(`orders/${order_id}/confirm`));
};

export const getPrescription = (order_id) => {
  return getRequest(portalEndpoint(`orders/${order_id}/prescription`)).then(
    (response) => response
  );
};

export const getOrderStatusHistory = (order_id) => {
  return getRequest(portalEndpoint(`orders/${order_id}/status_history`)).then(
    (response) => response
  );
};

export const sendPaymentLink = (order_id) => {
  return postRequest(portalEndpoint(`orders/${order_id}/payment_link`)).then(
    (response) => response
  );
};

export const getOrderFilters = () => {
  return getRequest(portalEndpoint(`orders/get_filters`)).then(
    (response) => response
  );
};

export const updateHubTransferAction = (order_id, action) => {
  return patchRequest(portalEndpoint(`orders/${order_id}/attached-documents`), {
    hub_attached_documents: action,
  }).then((response) => response);
};

export const {
  getList,
  getOne,
  getOrderPrice,
  submit,
  create,
  update,
  cancel,
  fetchNotes,
} = commonApiServices(DISPENSER, "orders");
