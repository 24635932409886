import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";
import _uniqBy from "lodash/uniqBy";
import { Wrapper, SearchIcon } from "./styles";
import SegmentInput from "../SegmentInput";
import Hints from "./Hints";
import BaseInputWithValidation from "../BaseInputWithValidation";
import { DEBOUNCE_DELAY_BEFORE_API_CALL } from "constants/api/helpers";
import { isArrayEqual } from "utils/array-helpers";

export const generateSearchHints = (array) =>
  array.map((elem) => ({
    id: elem._id,
    text: elem.name || `${elem.first_name} ${elem.last_name}`,
  }));

class SearchWithHints extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showHints: false,
      text: "",
      image: null,
      active: 0,
      hintStore: [],
    };
    this.debouncedSearch = _debounce((value) => {
      this.setState({ hintStore: [] }, () => {
        props.onSearch(value, 0, props.limit);
      });
    }, DEBOUNCE_DELAY_BEFORE_API_CALL);

    this.showHints = this.showHints.bind(this);
    this.hideHints = this.hideHints.bind(this);
    this.updateHints = this.updateHints.bind(this);

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleLazyLoad = this.handleLazyLoad.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { initialInput } = this.props;
    if (initialInput) {
      this.setState({ text: initialInput });
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { hintStore } = this.state;
    const { value, hints, noFocus } = this.props;

    const valueUpdated = value !== prevProps.value;
    const hintsUpdated = !isArrayEqual(hints, prevProps.hints);
    const receivedHints = hintsUpdated && value;

    if (valueUpdated || receivedHints) {
      this.afterSetValue(this.props);
      if (value === "") {
        // handle clicking to `Add`
        if (!noFocus) {
          this.input.focus();
        }

        this.hideHints();
      }
    }

    if (prevProps.hints !== hints) {
      if (valueUpdated || !hintStore.length) {
        this.updateHints(hints);
      } else {
        this.updateHints(_uniqBy([...hintStore, ...hints], "id"));
      }
    }

    this.setInputText(prevProps);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { searchId } = this.props;
    const classNames = event.target.className.split(" ");
    if (
      !classNames.includes("Hint") &&
      !event.target.closest(`.Hint-wrapper-${searchId}`)
    ) {
      this.hideHints();
    } else {
      // TODO
      // find out way to implement this:
      // this.input.focus();
    }
  }

  afterSetValue(props) {
    const { value } = props;
    const { hintStore } = this.state;
    if (value === null) return;
    if (hintStore.length) {
      const { text, image } = hintStore.find((h) => h.id === value) || {
        text: "",
      };

      this.setState({ text, image });
    }
  }

  setInputText(prevProps) {
    const { initialInput, clearInput } = this.props;

    if (!prevProps.initialInput && initialInput) {
      this.setState({ text: initialInput });
    }

    if (!prevProps.clearInput && clearInput) {
      this.setState({ text: "" });
    }
  }

  handleKeyPress({ key }) {
    const { active, showHints, hintStore } = this.state;

    switch (key) {
      case "Enter":
        if (hintStore.length > active && showHints) {
          this.handleSelect(hintStore[active].id, hintStore[active]);
        }
        break;
      case "ArrowDown":
        this.setState({ active: (active + 1) % hintStore.length });
        break;
      case "ArrowUp": {
        const value = active > 0 ? active : hintStore.length;
        this.setState({ active: (value - 1) % hintStore.length });
        break;
      }
      default:
        if (!showHints) this.showHints();
    }
  }

  handleChange(event) {
    event.persist();
    const { value, onChange } = this.props;
    const inputValue = event.target.value?.trimStart();

    this.setState({ text: inputValue, active: 0 });

    this.debouncedSearch(inputValue);

    if (value) onChange(null);
  }

  handleClick({ target }) {
    const { forceRefresh, onSearch, limit } = this.props;
    const { value } = target;

    if (!this.state.showHints) {
      this.showHints();
    }

    if ((!value.length || forceRefresh) && !this.state.showHints) {
      this.setState({ hintStore: [] }, () => {
        onSearch(value, 0, limit);
      });
    }
  }

  handleLazyLoad({ skip, limit }) {
    this.props.onSearch(this.state.text, skip, limit);
  }

  handleChangeActive(active) {
    this.setState({ active });
  }

  handleSelect(id, selected) {
    const { onChange } = this.props;

    onChange(id, selected, this.state.hintStore);
    this.hideHints();
  }

  showHints() {
    this.setState({ showHints: true });
  }

  hideHints() {
    this.setState({ showHints: false });
  }

  updateHints(values) {
    this.setState({ hintStore: values });
  }

  render() {
    const {
      searchId,
      placeholder,
      maxHints,
      limit,
      searching,
      searchIcon,
      emptyResultHint,
      style,
      inputStyle,
      testID,
      label,
      error,
      ...inputProps
    } = this.props;

    const { text, showHints, hintStore, active } = this.state;

    return (
      <BaseInputWithValidation
        error={error}
        label={label}
        className="dr-search-error"
      >
        <Wrapper style={style} className={`Hint-wrapper-${searchId}`}>
          <SegmentInput
            className={error ? "form-control" : ""}
            placeholder={placeholder || "Search..."}
            style={inputStyle}
            {...inputProps}
            value={text}
            onKeyUp={this.handleKeyPress}
            onChange={this.handleChange}
            onClick={this.handleClick}
            innerRef={(x) => (this.input = x)}
            searchIconFloat={searchIcon.float}
            searchIconScale={searchIcon.scale}
            autoComplete="off"
            data-test={testID}
          />
          <SearchIcon float={searchIcon.float} scale={searchIcon.scale} />
          <Hints
            show={showHints}
            searching={searching}
            hints={hintStore}
            maxHints={maxHints}
            limit={limit}
            emptyResultHint={emptyResultHint}
            onSelect={this.handleSelect}
            onLazyLoad={this.handleLazyLoad}
            active={active}
            onChangeActive={this.handleChangeActive}
            testID={`${testID}_HINTS`}
          />
        </Wrapper>
      </BaseInputWithValidation>
    );
  }
}

SearchWithHints.propTypes = {
  searchId: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  initialInput: PropTypes.string,
  placeholder: PropTypes.string,
  emptyResultHint: PropTypes.string,
  testID: PropTypes.string,
  hints: PropTypes.array,
  maxHints: PropTypes.number, // max number of records that can be fetched
  limit: PropTypes.number, // number of records fetched every time
  forceRefresh: PropTypes.bool, // controls the list reload when clicked (special case use)
  searching: PropTypes.bool,
  clearInput: PropTypes.bool,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  searchIcon: PropTypes.object,
  noFocus: PropTypes.bool,
};

SearchWithHints.defaultProps = {
  searchId: "droice",
  hints: [],
  testID: "",
  limit: 10,
  allowHints: true,
  forceRefresh: false, // special case prop, that controls fetch on click
  clearInput: false,
  noFocus: false,
};

export default SearchWithHints;
