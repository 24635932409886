import { push } from "connected-react-router";
import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";
import { APIModule } from "api";
import { getPortal, isHubDispenser } from "utils/auth-helpers";
import { recalcOrderPrice } from "utils/price-helpers";
import { prepare as productPrepare } from "api/product-helpers";
import { prepare as preparePatient } from "utils/patient-helpers";
import { isPaymentDefault } from "utils/payment-helpers";
import { PRACTICE_ROLES } from "constants/accountRoles";
import * as defaults from "constants/defaults/paymentAndShipping";
import {
  getOrder,
  getPatient,
  getTemplate,
  getPaymentAndShipping,
} from "modules/orderFlow/selectors";
import { ONE_TIME, SUBSCRIPTION } from "constants/orders/order_types";
import { OTC, COMPOUND } from "constants/productTypes";
import {
  updateArrayItemById,
  updateItemPriceById,
} from "utils/product-helpers";
import { removeById } from "utils/state-helpers";
import { isEmptyAddress } from "api/address-helpers";
import { paymentDetailsUpdate } from "shared/containers/PaymentAndShipping/actions";
import { showPopup } from "app-shared/containers/Popup/actions";
import {
  subscriptionIncompatibleProductTypesMessage,
  oneTimeIncompatibleProductTypesMessage,
} from "text/OrderNew";

export * from "./templateActions";

export const createOrderForPatient = (patient_id) => ({
  type: types.CREATE_ORDER_FOR_NEW_PATIENT,
  patient_id,
});

export const handleQuery = (query) => ({
  type: types.HANDLE_QUERY_NEW_ORDER,
  query,
});

export const cancel = (navTo = null) => {
  return (dispatch) => {
    const portal = getPortal();
    const pathAfterCancel = navTo || `/${portal}`;

    dispatch(push(pathAfterCancel));
    dispatch({ type: types.NEW_ORDER_FLOW_RESET });
  };
};

export const onSearchPatient = (input, skip, limit) => {
  return async (dispatch) => {
    dispatch({ type: types.SEARCH_PATIENT_REQUEST });

    const portal = getPortal();
    const { patients, count } = await APIModule(portal).patients.getAll({
      q: input,
      skip,
      limit,
    });

    dispatch({
      type: types.SEARCH_PATIENT_SUCCESS,
      results: patients,
      count,
    });
  };
};

export const onSearchDoctor = (input, skip, limit) => {
  return async (dispatch) => {
    dispatch({ type: types.SEARCH_DOCTOR_REQUEST });

    const portal = getPortal();
    const { doctors, count } = await APIModule(portal).doctors.getAll({
      q: input,
      skip,
      limit,
      filters: { is_active: true, role: PRACTICE_ROLES.DOCTOR },
    });

    dispatch({
      type: types.SEARCH_DOCTOR_SUCCESS,
      results: doctors,
      count,
    });
  };
};

export const update = (updates) => ({
  type: types.UPDATE_NEW_ORDER,
  updates,
});

export const onSearchProduct = (q, skip, limit) => {
  return async (dispatch, getState) => {
    dispatch({ type: types.SEARCH_PRODUCT_REQUEST });

    const { type } = getOrder(getState());
    const params = {
      q,
      skip,
      limit,
      type: type === SUBSCRIPTION && isHubDispenser() ? [OTC, COMPOUND] : null,
    };
    const portal = getPortal();
    const { products, count } = await APIModule(portal).products.searchActive(
      params
    );

    dispatch({
      type: types.SEARCH_PRODUCT_SUCCESS,
      results: products,
      count,
    });
  };
};

export const onAddProduct = (product_id) => {
  return async (dispatch, getState) => {
    dispatch({ type: types.NEW_ORDER_FETCH_PRODUCT_REQUEST });

    const order = getOrder(getState());
    const product = await APIModule(getPortal()).products.getOne(product_id);
    const preparedProduct = productPrepare(product);
    const items = order.items.map((i) => i).concat(preparedProduct);
    const price = await recalcOrderPrice(items);

    dispatch({
      type: types.NEW_ORDER_FETCH_PRODUCT_SUCCESS,
      product: preparedProduct,
      price,
    });
  };
};

export const updateCadence = (cadence) => ({
  type: types.NEW_ORDER_UPDATE_SUBSCRIPTION,
  updates: { cadence },
});

export const removeOrder = (group_no) => ({
  type: types.NEW_ORDER_REMOVE_ORDER,
  group_no,
});

export const onUpdateProduct = (id, updates) => async (dispatch, getState) => {
  const order = getOrder(getState());

  const items = updateArrayItemById(order.items, id, updates, "product_id");

  let price = order.price;

  if (updates.product_id || updates.amount || updates.refills) {
    price = await recalcOrderPrice(items);
  }

  dispatch({
    type: types.UPDATE_PRODUCT_IN_NEW_ORDER,
    items,
    price,
  });
};

export const onUpdateProductPrice = (id, price) => async (
  dispatch,
  getState
) => {
  const order = getOrder(getState());
  const items = updateItemPriceById(order.items, id, price);
  const updatedPrice = await recalcOrderPrice(items);

  dispatch({
    type: types.UPDATE_PRODUCT_PRICE_IN_NEW_ORDER,
    items,
    price: updatedPrice,
  });
};

export const onRemoveProduct = (id) => async (dispatch, getState) => {
  const order = getOrder(getState());
  const items = removeById(order.items, id, "product_id");
  const price = await recalcOrderPrice(items);

  dispatch({
    type: types.REMOVE_PRODUCT_FROM_NEW_ORDER,
    items,
    price,
  });
};

export const fetchPatient = (patient_id) => {
  return async (dispatch) => {
    dispatch({ type: types.NEW_ORDER_FETCH_PATIENT_REQUEST });

    const portal = getPortal();
    const patient = await APIModule(portal).patients.getOne(patient_id);

    dispatch({
      type: types.NEW_ORDER_FETCH_PATIENT_SUCCESS,
      patient: preparePatient(patient),
    });
  };
};

export const onDoctorSelect = (doctor) => {
  if (!doctor) {
    return (dispatch) => {
      dispatch({ type: types.NEW_ORDER_RESET_DOCTOR });
    };
  }
  return (dispatch) => {
    dispatch({ type: types.NEW_ORDER_SELECT_DOCTOR, doctor });
  };
};

const checkItemsCompatible = (items) => {
  const firstItemType = items[0].type;
  return !items.find((item) => item.type !== firstItemType);
};

export const checkProductTypesCompatibility = (orderContents) => {
  const { items, groups } = orderContents;

  if (items.length) {
    return checkItemsCompatible(items);
  } else {
    return groups.every((group) => checkItemsCompatible(group.items));
  }
};

export const prepareOrderAddresses = () => (dispatch, getState) => {
  const patient = getPatient(getState());
  const {
    shipping_address,
    billing_address,
    health_insurance,
    card,
  } = getPaymentAndShipping(getState());

  const isPaymentAddressesEmpty =
    isEmptyAddress(shipping_address) && isEmptyAddress(billing_address);

  const isPatientAddressesEmpty =
    isEmptyAddress(patient.payment_details.shipping_address) &&
    isEmptyAddress(patient.payment_details.billing_address);

  if (isPaymentAddressesEmpty && !isPatientAddressesEmpty) {
    dispatch(
      paymentDetailsUpdate(
        objectAssign(
          {},
          {
            shipping_address: patient.payment_details.shipping_address,
            billing_address: patient.payment_details.billing_address,
          }
        )
      )
    );
  }

  const { isCardDefault, isInsuranceDefault } = isPaymentDefault(
    card && card.last_4 ? card : defaults.card,
    health_insurance
  );

  const {
    isCardDefault: isPatientCardDefault,
    isInsuranceDefault: isPatientInsuranceDefault,
  } = isPaymentDefault(
    patient.payment_details.card && patient.payment_details.card.last_4
      ? patient.payment_details.card
      : defaults.card,
    patient.payment_details.health_insurance
  );

  if (isInsuranceDefault && !isPatientInsuranceDefault) {
    dispatch(
      paymentDetailsUpdate(
        objectAssign(
          {},
          { health_insurance: patient.payment_details.health_insurance }
        )
      )
    );
  }

  if (isHubDispenser() && isCardDefault && !isPatientCardDefault) {
    dispatch(
      paymentDetailsUpdate(
        objectAssign({}, { card: patient.payment_details.card })
      )
    );
  }
};

const prepareForOrderReview = () => {
  return async (dispatch, getState) => {
    dispatch({ type: types.NEW_ORDER_REQUEST });
    const order = getOrder(getState());
    const template = getTemplate(getState());

    const orderContents = { items: order.items, groups: template.groups };

    const isProductsCompatible = checkProductTypesCompatibility(orderContents);
    const compatibilityWarningText =
      order.type === ONE_TIME
        ? oneTimeIncompatibleProductTypesMessage
        : subscriptionIncompatibleProductTypesMessage;

    if (!isProductsCompatible) {
      dispatch(showPopup(compatibilityWarningText));
    } else {
      const portal = getPortal();
      const price = await APIModule(portal).orders.getOrderPrice(orderContents);

      dispatch(prepareOrderAddresses());
      dispatch(update({ price }));
    }

    return isProductsCompatible;
  };
};

export const skipPayment = () => (dispatch) =>
  dispatch(submit({ nextPage: "review" }));

export const submit = ({ nextPage = "payment" }) => {
  return async (dispatch, getState) => {
    const order_id = getOrder(getState()).order_id || "new";
    const isReadyForReview = await dispatch(prepareForOrderReview());

    if (isReadyForReview)
      dispatch(push(`/${getPortal()}/orders/${order_id}/${nextPage}`));
  };
};

export const closeAlert = () => ({
  type: types.NEW_ORDER_CLOSE_ALERT,
});

export const getCourierServices = () => (dispatch) => {
  dispatch({ type: types.FETCH_CARRIERS_REQUEST });

  APIModule(getPortal())
    .dispensers.fetchCarriers()
    .then(({ carriers }) => {
      const carrierOptions = ["Other", ...carriers];

      dispatch({
        type: types.FETCH_CARRIERS_SUCCESS,
        carriers: carrierOptions.map((option) => ({
          label: option,
          value: option,
        })),
      });
    })
    .catch(() => {
      dispatch({ type: types.FETCH_CARRIERS_FAILURE });
    });
};
