import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import BaseInputWithValidation from "../BaseInputWithValidation";
import { formatter } from "utils/formatter";
import styled from "styled-components";

const Input = styled.input`
  &::placeholder {
    font-size: 14px;
    color: #97999b !important;
  }
`;

class DefaultInput extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const value = event.target.value;
    const { mask, onChange } = this.props;
    onChange(mask ? formatter(mask, value) : value);
  }

  render() {
    const {
      value,
      type,
      placeholder,
      mask,
      disabled,
      name,
      onChange,
      onBlur,
      onFocus,
      inputStyle,
      maxLength,
      ...baseProps
    } = this.props;
    const formattedValue = mask ? formatter(mask, value) : value;

    return (
      <BaseInputWithValidation {...baseProps}>
        <Input
          type={type}
          name={name}
          className="form-control"
          placeholder={placeholder}
          value={formattedValue || ""}
          onChange={name ? onChange : this.handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          style={inputStyle}
          maxLength={maxLength}
        />
      </BaseInputWithValidation>
    );
  }
}

DefaultInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  className: PropTypes.string,
  mask: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  inputStyle: PropTypes.object,
};

DefaultInput.defaultProps = {
  type: "text",
  placeholder: "",
  value: "",
  mask: null,
};

export default DefaultInput;
