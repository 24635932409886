import React from "react";
import { Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import useHotjar from "react-use-hotjar";
import Loading from "shared/components/Loading";
import { redirectPath } from "utils/auth-helpers";

const PracticeApp = Loadable({
  loader: () => import("shared/containers/AppPracticeDispenser"),
  loading: Loading,
});

const PracticePortal = (params) => {
  const { location } = params;
  const path = redirectPath("practice");
  const { initHotjar } = useHotjar();

  React.useEffect(() => {
    const hotjarId = Number(process.env.HOTJAR_ID);
    hotjarId && initHotjar(hotjarId, 6);
  }, [initHotjar]);

  if (location.pathname.includes("doctor")) {
    return <Redirect to={"/practice"} />;
  }

  if (path) {
    return <Redirect to={path} />;
  } else {
    return <PracticeApp {...params} />;
  }
};

export default PracticePortal;
