export const FETCH_SETTINGS_REQUEST = "FETCH_DISPENSER_SETTINGS_REQUEST";
export const FETCH_SETTINGS_SUCCESS = "FETCH_DISPENSER_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_DISPENSER_SETTINGS_FAILURE";

export const FETCH_DISPENSER_SETTINGS_REQUEST =
  "FETCH_DISPENSER_SETTINGS_SUCCESS";
export const FETCH_DISPENSER_SETTINGS_SUCCESS =
  "FETCH_DISPENSER_IN_DISPENSER_SETTINGS_SUCCESS";
export const FETCH_DISPENSER_SETTINGS_FAILURE =
  "FETCH_DISPENSER_IN_DISPENSER_SETTINGS_FAILURE";
export const FETCH_DISPENSER_SETTINGS_ONLY_SUCCESS =
  "FETCH_DISPENSER_SETTINGS_ONLY_SUCCESS";

export const SUBMIT_DISPENSER_SETTINGS_REQUEST =
  "SUBMIT_DISPENSER_SETTINGS_REQUEST";
export const SUBMIT_DISPENSER_SETTINGS_SUCCESS =
  "SUBMIT_DISPENSER_SETTINGS_SUCCESS";
export const SUBMIT_DISPENSER_SETTINGS_FAILURE =
  "SUBMIT_DISPENSER_SETTINGS_FAILURE";

export const SUBMIT_HOLPITAL_SETTINGS_REQUEST =
  "SUBMIT_HOLPITAL_IN_DISPENSER_SETTINGS_REQUEST";
export const SUBMIT_HOLPITAL_SETTINGS_SUCCESS =
  "SUBMIT_HOLPITAL_IN_DISPENSER_SETTINGS_SUCCESS";
export const SUBMIT_HOLPITAL_SETTINGS_FAILURE =
  "SUBMIT_HOLPITAL_IN_DISPENSER_SETTINGS_FAILURE";

export const SAVE_DISPENSER_SETTINGS_REQUEST =
  "SAVE_DISPENSER_SETTINGS_REQUEST";
export const SAVE_DISPENSER_SETTINGS_SUCCESS =
  "SAVE_DISPENSER_SETTINGS_SUCCESS";
export const SAVE_DISPENSER_SETTINGS_FAILURE =
  "SAVE_DISPENSER_SETTINGS_FAILURE";
