import * as yup from "yup";
import moment from "moment";
import get from "lodash/get";
import { checkEmailUniqueness } from "api/users";

export const requiredMessage = "Required field";
export const safeStringMessage = "Safe text required";
export const invalidRegexMessage = "Invalid Format";

const inputMaxCharsAmount = 126;
const numberMaxCharsAmount = 20;
const textareaMaxCharsAmount = 1000;
const passwordCharsMinAmount = 8;
const zipCodeLength = 5;

const generalInputRegExp = /^((?![<;\\>])[\w\s()-`~@#$%^&*(),.!?-_=+[/\]{}|'’:”"/])*$/;

const emailRegExp = /[a-z0-9]+([-+._][a-z0-9]+){0,2}@.*?(\.(a(?:[cdefgilmnoqrstuwxz]|ero|(?:rp|si)a)|b(?:[abdefghijmnorstvwyz]iz)|c(?:[acdfghiklmnoruvxyz]|at|o(?:m|op))|d[ejkmoz]|e(?:[ceghrstu]|du)|f[ijkmor]|g(?:[abdefghilmnpqrstuwy]|ov)|h[kmnrtu]|i(?:[delmnoqrst]|n(?:fo|t))|j(?:[emop]|obs)|k[eghimnprwyz]|l[abcikrstuvy]|m(?:[acdeghklmnopqrstuvwxyz]|il|obi|useum)|n(?:[acefgilopruz]|ame|et)|o(?:m|rg)|p(?:[aefghklmnrstwy]|ro)|qa|r[eosuw]|s[abcdeghijklmnortuvyz]|t(?:[cdfghjklmnoprtvwz]|(?:rav)?el)|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw])\b){1,2}/;
const phoneRegExp = /\(+([0-9]){3}\)+\s([0-9]){3}-([0-9]){4}/;
const zipcodeRegExp = /(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}/;
const creditCardNumberRegExp = /^((4\d{3})|(5[1-5]\d{2})|(6011))-?\d{4}-?\d{4}-?\d{4}|3[4,7]\d{13}$/;
const creditCardCVVRegExp = /^([0-9]{3,4})$/;
const cardExpiry = /^(0[1-9]|1[0-2]|[1-9])\/(1[4-9]|[2-9][0-9]|20[1-9][1-9])$/;
const passwordRegExp = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9]/;

const decimalNumSupportRexExp = /^\d{0,10}(\.\d{1,2})?$/;
const numberWithSpacesRegExp = /^(?=.*\d)[\d ]+$/;

export const safeString = (msg = safeStringMessage) =>
  yup
    .string()
    .transform((value) => value?.trim()) //removes trailing and leading spaces
    .max(inputMaxCharsAmount)
    .matches(generalInputRegExp, msg);

export const safeStringRequired = (msg = requiredMessage) =>
  safeString().required(msg);

export const shortSafeString = () => safeString().max(numberMaxCharsAmount);
export const shortSafeStringRequired = () =>
  shortSafeString().required(requiredMessage);

export const textareaSafeString = () =>
  safeString().max(textareaMaxCharsAmount);

export const textareaSafeStringRequired = () =>
  safeString().max(textareaMaxCharsAmount).required(requiredMessage);

export const regexMatchRequired = (pattern, msg = invalidRegexMessage) =>
  yup.string().matches(pattern, msg).required(requiredMessage);

export const password = () =>
  yup
    .string()
    .min(
      passwordCharsMinAmount,
      `At least ${passwordCharsMinAmount} characters required.`
    )
    .matches(
      passwordRegExp,
      "Password must contain: an uppercase letter, a number AND a special character (!, @, #, etc.)"
    );

export const passwordRequired = (msg = requiredMessage) =>
  password().required(msg);

export const number = () =>
  yup
    .string()
    .matches(numberWithSpacesRegExp, "Invalid Number")
    .max(numberMaxCharsAmount, "Maximum amount of digits exceeded");

export const numberRequired = () => number().required(requiredMessage);

export const decimalNumber = () =>
  yup.string().matches(decimalNumSupportRexExp, "Invalid Number");

export const decimalNumberRequired = () =>
  decimalNumber().required(requiredMessage);

export const safeTextUnlimitedChars = () =>
  yup.string().matches(generalInputRegExp, safeStringMessage);

export const validateEmailWithRegex = (email) => {
  return !!String(email).toLowerCase().match(emailRegExp);
};

export const email = (options = {}) => {
  const {
    msg = "Invalid Email Address",
    initialEmail,
    checkEmailUnique,
  } = options;
  return yup
    .string()
    .matches(emailRegExp, msg)
    .test("isUnique", "Email already taken", async (value) => {
      const isValidEmail = yup
        .string()
        .matches(emailRegExp, msg)
        .isValidSync(value);
      if (value && value !== initialEmail && isValidEmail && checkEmailUnique) {
        const { is_valid } = await checkEmailUniqueness(value);
        return is_valid;
      }
      return true;
    });
};

export const emailRequired = (options) =>
  email(options).required(requiredMessage);

export const phoneNumber = () =>
  yup.string().matches(phoneRegExp, "Invalid phone number");

export const phoneNumberRequired = () =>
  phoneNumber().required(requiredMessage);

export const zipcode = (msg = "Invalid Zip Code") =>
  yup
    .string(msg)
    .length(zipCodeLength, msg)
    .matches(zipcodeRegExp, { message: invalidRegexMessage });

export const zipcodeOldFormatValidation = (zip, msg = "INVALID ZIP CODE") =>
  zip && zip.match(zipcodeRegExp) ? null : msg;

export const zipcodeRequired = () => zipcode().required(requiredMessage);

export const dateOfBirth = (msg = "Invalid Date format") => {
  return yup.string(msg).test("date_of_birth", msg, (value) => {
    const dateFormat = '"MM/DD/YYYY"';
    const today = moment();
    const date = moment(value, dateFormat);
    const minYear = 1850;
    const dobYear = date.year();

    return (
      moment(value, dateFormat).isValid() &&
      dobYear > minYear &&
      date.diff(today) <= 0
    );
  });
};

export const dateOfBirthRequired = () =>
  dateOfBirth().required(requiredMessage);

export const date = (msg = "Invalid Date") => yup.date().typeError(msg);

export const dateRequired = () => date.required(requiredMessage);

export const creditCardNumber = (
  number,
  msg = "INVALID CREDIT CARD NUMBER FORMAT"
) =>
  number && number.replace(/\s*/g, "").match(creditCardNumberRegExp)
    ? null
    : msg;

export const creditCardCVV = (cvv, msg = "INVALID CVV FORMAT") =>
  cvv && cvv.match(creditCardCVVRegExp) ? null : msg;

export const isIdtechMask = (mask, msg = "INVALID FORMAT") =>
  mask && mask.includes("(Expires") ? null : msg;

export const creditCard = () =>
  yup.object().shape({
    security_code: regexMatchRequired(
      creditCardCVVRegExp,
      "Invalid cvv format"
    ),
    expiration_date: regexMatchRequired(cardExpiry, "Invalid expiry date"),
    name: safeStringRequired(),
    number: yup
      .string()
      .transform((value, original) => original && original.replace(/\s*/g, ""))
      .matches(creditCardNumberRegExp, "Invalid credit card number format")
      .required(requiredMessage),
  });

export const getPropsForFormikField = ({
  values,
  touched,
  errors,
  setFieldTouched,
  handleChange,
}) => (name) => {
  const isTouched = get(touched, name);
  return {
    value: get(values, name),
    error: isTouched && get(errors, name),
    name,
    onChange: handleChange,
    onBlur: () => setFieldTouched(name, true),
  };
};

export default yup;
