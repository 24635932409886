import styled from "styled-components";

const SegmentInput = styled.input`
  width: ${(props) => props.width || "100%"};
  border: none;
  height: 45px;
  border-bottom: 1px solid #c0c0c0;
  padding-${(props) => props.searchIconFloat}: ${(props) =>
  Math.floor(10 + 35 * (props.searchIconScale || 1))}px;
  transition: 0.5s ease all;
  background: transparent;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "15px")};
  font-weight: ${(props) => (props.fontWeightBold ? "bold" : "normal")};

  &:disabled {
    background: transparent;
    color: #f0f0f0;

    &:hover {
      border-bottom: 1px solid #c0c0c0;
    }
  }

  &::placeholder {
    font-size: ${(props) =>
      props.placeholderFontSize ? `${props.placeholderFontSize}px` : "18px"};
  }
`;

export const SegmentLabel = styled.label`
  color: #0e2439;
  font-weight: bold;
  margin-bottom: 5px;
  display: inline-block;
  max-width: 100%;
`;

export default SegmentInput;
