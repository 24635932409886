import isEmpty from "lodash/isEmpty";
import * as types from "../constants/actionTypes";
import * as defaults from "constants/defaults/paymentAndShipping";
import * as validations from "../constants/validations";
import { isValidURL } from "../../OrderNew/constants/validations";
import { getPortal } from "utils/auth-helpers";
import {
  updateStateWithErasingErrors,
  newValidate as validate,
} from "utils/validators";
import { LOCATION_CHANGE } from "connected-react-router";
import { NEW_ORDER_FETCH_ORDER_SUCCESS } from "shared/containers/OrderNew/constants/actionTypes";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";
import objectAssign from "object-assign";

const billingAddressReducer = (state = defaults.address, action) => {
  switch (action.type) {
    case types.PAYMENT_AND_SHIPPING_BILLING_ADDRESS_UPDATE:
      return updateStateWithErasingErrors(state, action.updates);
    case NEW_ORDER_FETCH_ORDER_SUCCESS: {
      const { payment_details } = action.order;
      return payment_details && !isEmpty(payment_details.billing_address)
        ? payment_details.billing_address
        : defaults.address;
    }
    case types.PAYMENT_AND_SHIPPING_BULK_UPDATE:
      return objectAssign({}, state, action.billing_address, { errors: {} });
    case types.PAYMENT_AND_SHIPPING_VALIDATE: {
      return objectAssign({}, state, {
        errors: validate(state, validations.address),
      });
    }
    case types.PAYMENT_AND_SHIPPING_RESET_ALL:
    case types.PAYMENT_AND_SHIPPING_RESET:
      return defaults.address;
    case LOCATION_CHANGE: {
      const { pathname } = action.payload.location;
      if (
        !pathname.includes("/orders/") &&
        !isValidURL(pathname?.replace(`/${getPortal()}/`, "") || "")
      ) {
        return defaults.address;
      }
      return state;
    }
    case LOAD_FROM_CACHE:
      return action.state.paymentAndShipping.billing_address;
    default:
      return state;
  }
};

export default billingAddressReducer;
