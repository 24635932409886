import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";
import { ASCENDING } from "constants/queryParams";
import {
  SEARCH_DOCTOR_REQUEST,
  SEARCH_DOCTOR_SUCCESS,
  SEARCH_PRODUCT_REQUEST,
  SEARCH_PRODUCT_SUCCESS,
} from "shared/containers/OrderNew/constants/actionTypes";
import { generateSearchHints } from "app-shared/components/SearchWithHints";
import {
  FETCH_DISPENSERS_REQUEST,
  FETCH_DISPENSERS_SUCCESS,
} from "shared/containers/OrderDetailsPage/constants/actionTypes";

export const defaultState = {
  count: 0,
  loadingOrders: false,
  searching: false,
  doctors: [],
  maxDoctors: 0,
  dispensers: [],
  maxDispensers: 0,
  payors: [],
  maxPayors: 0,
  sortColumn: {},
  sortOrder: ASCENDING,
  appliedFilters: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_PAYORS_REQUEST:
    case SEARCH_DOCTOR_REQUEST:
    case FETCH_DISPENSERS_REQUEST:
    case SEARCH_PRODUCT_REQUEST:
      return objectAssign({}, state, { searching: true });
    case SEARCH_DOCTOR_SUCCESS:
      return objectAssign({}, state, {
        doctors: generateSearchHints(action.results),
        maxDoctors: action.count,
        searching: false,
      });

    case FETCH_DISPENSERS_SUCCESS: {
      return objectAssign({}, state, {
        dispensers: generateSearchHints(action.dispensers),
        maxDispensers: action.count,
        searching: false,
      });
    }

    case types.FETCH_PAYORS_SUCCESS:
      return objectAssign({}, state, {
        payors: generateSearchHints(action.payors),
        maxPayors: action.count,
        searching: false,
      });

    case SEARCH_PRODUCT_SUCCESS: {
      return objectAssign({}, state, { searching: false });
    }
    case types.FETCH_ORDERS_SUCCESS: {
      return objectAssign({}, state, {
        count: action.count,
        appliedFilters: action.filters,
        loadingOrders: false,
      });
    }
    case types.SORT_ORDERS_REQUEST:
      return objectAssign({}, state, {
        sortColumn: action.payload.column,
        sortOrder: action.payload.sorting,
      });
    case types.FETCH_ORDERS_REQUEST:
      return objectAssign({}, state, { loadingOrders: true });
    case types.FETCH_ORDERS_FAILURE:
      return objectAssign({}, state, { loadingOrders: false });
    default:
      return state;
  }
};
