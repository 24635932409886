import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";

export const defaultState = {
  isDefault: true,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.PAYMENT_AND_SHIPPING_RESET_ALL:
      return objectAssign({}, state, { isDefault: true });
    case types.INSURANCE_VALIDATE:
    case types.PAYMENT_AND_SHIPPING_VALIDATE:
      return objectAssign({}, state, { isDefault: false });
    case LOAD_FROM_CACHE:
      return action.state.paymentAndShipping.ui;
    default:
      return state;
  }
};
