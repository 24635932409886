import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";
import { HintsWrapper, HintsSearchingAlert, HintsPrompt } from "./styles";
import Hint from "./Hint";

class Hints extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      reachedBottom: false,
      loaderPosition: 0,
    };

    this.observer = null;

    this.intersectionCallback = this.intersectionCallback.bind(this);
    this.observeComponent = this.observeComponent.bind(this);
    this.unObserveComponent = this.unObserveComponent.bind(this);
    this.debouncedSearch = this.debouncedSearch.bind(this);
  }

  componentDidMount() {
    const hintDiv = this.hintContainer;
    if (hintDiv) {
      const options = {
        root: hintDiv,
        rootMargin: "140px",
      };

      this.observer = new IntersectionObserver(
        this.intersectionCallback,
        options
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { hints } = this.props;
    const lastHint = this.hintContainer.lastChild;
    const isNewHints =
      hints &&
      hints.length > prevProps.hints.length &&
      this.state.reachedBottom;

    if (hints.length && !lastHint.classList.contains("observed")) {
      this.observeComponent(lastHint);
    }

    if (isNewHints) {
      this.setState({ reachedBottom: false });
    }

    if (prevProps.hints.length && !hints.length) {
      this.setState({ loaderPosition: 0 });
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  debouncedSearch = _debounce(() => {
    const { onLazyLoad, hints, limit } = this.props;

    onLazyLoad({ skip: hints.length || 0, limit });
    this.setState({ reachedBottom: true });
  }, 500);

  intersectionCallback(entries) {
    if (!entries[0].isIntersecting) return;
    const hintDiv = this.hintContainer;
    const { hints, maxHints } = this.props;
    const { reachedBottom } = this.state;
    const noRecordsLeft = hints.length === maxHints;

    if (!reachedBottom && !noRecordsLeft) {
      this.setState({ loaderPosition: hintDiv.scrollTop });
      this.debouncedSearch();
    }
    return !reachedBottom && !noRecordsLeft ? this.onScrollToBottom : null;
  }

  unObserveComponent(el) {
    if (el) {
      this.observer.unobserve(el);
      el.classList.remove("observed");
    }
  }

  observeComponent(el) {
    const prevEl = this.hintContainer.querySelector(".observed");
    this.unObserveComponent(prevEl);
    this.observer.observe(el);
    el.classList.add("observed");
  }

  render() {
    const {
      show,
      searching,
      hints,
      active,
      onSelect,
      onChangeActive,
      emptyResultHint,
      testID,
    } = this.props;

    const { loaderPosition } = this.state;

    return (
      <HintsWrapper
        innerRef={(x) => (this.hintContainer = x)}
        className="Hint"
        show={show}
        data-test={testID}
      >
        <HintsSearchingAlert
          position={loaderPosition}
          show={searching}
          data-test={`${testID}_SEARCHING_ALERT`}
        >
          <p>Searching...</p>
        </HintsSearchingAlert>
        {hints && hints.length > 0 ? (
          hints.map((hint, index) => (
            <Hint
              key={hint.id}
              hint={hint}
              onSelect={onSelect}
              active={active == index}
              onChangeActive={onChangeActive}
              index={index}
              testID={`${testID}_HINT`}
            />
          ))
        ) : (
          <HintsPrompt className="Hint" data-test={`${testID}_NO_RESULTS`}>
            {searching ? null : emptyResultHint || "No Search Results"}
          </HintsPrompt>
        )}
      </HintsWrapper>
    );
  }
}

Hints.propTypes = {
  show: PropTypes.bool,
  hints: PropTypes.array,
  maxHints: PropTypes.number,
  limit: PropTypes.number,
  emptyResultHint: PropTypes.string,
  onSelect: PropTypes.func,
  onLazyLoad: PropTypes.func,
  searching: PropTypes.bool,
  active: PropTypes.number,
  onChangeActive: PropTypes.func,
  testID: PropTypes.string.isRequired,
};

export default Hints;
