import * as types from "../constants/actionTypes";

export const defaultState = {
  showPopup: false,
  loading: false,
};

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_PATIENT_PROFILE_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_PATIENT_PROFILE_SUCCESS:
    case types.FETCH_PATIENT_PROFILE_FAILURE:
      return { ...state, loading: false };
    case types.TOGGLE_POPUP:
      return { ...state, showPopup: !state.showPopup };
    default:
      return state;
  }
};

export default uiReducer;
