import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";
import { initEmptyProperties } from "utils/object-helpers";

const dispenserReducer = (state = null, action) => {
  switch (action.type) {
    case types.FETCH_DISPENSER_SETTINGS_SUCCESS:
    case types.SUBMIT_DISPENSER_SETTINGS_SUCCESS: {
      const { settings, organization } = action.payload;
      return objectAssign(
        {},
        state,
        settings,
        organization
          ? {
              organization: {
                ...organization,
                address: initEmptyProperties(organization.address),
              },
            }
          : {}
      );
    }
    case types.FETCH_DISPENSER_SETTINGS_ONLY_SUCCESS: {
      const { settings } = action.payload;
      return objectAssign({}, state, settings);
    }
    default:
      return state;
  }
};

export default dispenserReducer;
